<div *ngIf="goBack" class="row d-md-flex section-header-back">
  <div class="col mx-2">
    <div class="row align-items-center">
      <div class="col col-auto col-md-5 pe-0 d-flex align-items-center go-back-set" (click)="goBack.action()">
        <m-icon iconName="arrowLeft1Regular"></m-icon>
        <a m-link color="default" class="my-0 ms-1 d-none d-md-inline-flex font-body02-regular">
          {{ 'COMMON.BACK_TO' | mTranslate }}
          {{ goBack.title | mTranslate }}
        </a>
        <h2 class="full-width my-0 ms-1 d-inline-flex d-md-none font-body01-regular">{{ title | mTranslate }}</h2>
      </div>
    </div>
  </div>

  <div class="col col-md-6 d-flex flex-row-reverse mx-2">
    <app-header-actions [actions]="actions"></app-header-actions>
  </div>
</div>
<div *ngIf="(title?.length || actions.length || alerts.length) && !goBack" class="row section-header">
  <div class="col mx-0 mx-md-auto col-md-9">
    <div class="row px-2 px-sm-3 px-md-0 align-items-center">
      <div class="col-7 pe-0">
        <h2 class="my-0 d-none d-md-block font-heading03-regular d-flex align-items-center">
          {{ title | mTranslate }}
        </h2>
        <h2 class="my-1 d-none d-sm-block d-md-none font-heading05-regular d-flex align-items-center">
          {{ title | mTranslate }}
        </h2>
        <h2 class="my-2 d-sm-none font-heading06-regular d-flex align-items-center">
          {{ title | mTranslate }}
        </h2>
      </div>
      <div class="col-5 d-flex flex-row-reverse">
        <app-header-actions [actions]="actions"></app-header-actions>
      </div>
    </div>
  </div>
</div>

<div *ngFor="let alert of alerts; trackBy: trackByIndexAlert" class="alert-container">
  <m-alert
    *ngIf="alert.visible"
    [color]="alert.color"
    titleLabel="{{ alert.title | mTranslate }}"
    description="{{ alert.description | mTranslate }}"
    [closeable]="alert.closeable"
    (closed)="alert.visible = false">
    <m-icon m-alert-icon [iconName]="alert.icon"></m-icon>
    <a *ngIf="alert.action" m-alert-action m-link (click)="(alert.action)">{{ alert.actionText || '' | mTranslate }}</a>
  </m-alert>
</div>
