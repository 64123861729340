import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { INotice } from '@pages/administration/interfaces/notices/notice.interface';
import { NoticeResourceModel } from '@pages/administration/models/notices/notice-resource.model';
import { HtmlModalComponent } from '@pages/home/html-modal/html-modal.component';
import { HeaderNoticeAlertService } from '@services/header-notice-alert/header-notice-alert.service';
import { ModalsService } from '@services/modals/modals.service';
import { ModalConfig } from '@shared/utils/device/device';

@Component({
  selector: 'app-header-notice-alert',
  templateUrl: './header-notice-alert.component.html',
  styleUrls: ['./header-notice-alert.component.scss']
})
export class HeaderNoticeAlertComponent implements OnInit {
  notice$!: Observable<NoticeResourceModel | undefined>;
  constructor(
    private headerNoticeService: HeaderNoticeAlertService,
    private modalService: ModalsService
  ) {}

  ngOnInit(): void {
    this.notice$ = this.headerNoticeService.notice$;
  }

  showNotice(notice: INotice): void {
    const modalConfig: ModalConfig = this.modalService.getConfigWithoutSize();
    const title =
      notice?.title?.length && notice.title.length > 70 ? notice?.title.substring(0, 70) + '...' : notice?.title;

    this.modalService.openModal(HtmlModalComponent, {
      data: {
        title,
        html: notice.content
      },
      ...modalConfig
    });
  }
}
