import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RolesEnum } from '@shared/consts/roles.const';

import { MPageNotFoundComponent } from '@mercadona/core-ui/page-not-found';

import { AuthorizationGuard } from './guards/authorization/authorization.guard';
import { ModalsGuard } from './guards/modals/modals.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [ModalsGuard],
    children: [
      {
        path: '',
        canActivate: [AuthorizationGuard],
        data: {
          title: 'home',
          roles: [
            RolesEnum.ROLE_ADMIN,
            RolesEnum.ROLE_DIRECTOR,
            RolesEnum.ROLE_PRESIDENCY,
            RolesEnum.ROLE_TRAVELLER,
            RolesEnum.ROLE_ADMIN_EXT
          ]
        },
        loadChildren: () => import('@pages/home/home-page.module').then((m) => m.HomePageModule)
      },
      {
        path: 'administration',
        canActivate: [AuthorizationGuard],
        data: {
          title: 'administration',
          roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_DIRECTOR, RolesEnum.ROLE_PRESIDENCY, RolesEnum.ROLE_ADMIN_EXT]
        },
        loadChildren: () =>
          import('@pages/administration/administration-page.module').then((m) => m.AdministrationPageModule)
      },
      {
        path: 'g200',
        canActivate: [AuthorizationGuard],
        data: {
          title: 'g200',
          roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_DIRECTOR, RolesEnum.ROLE_PRESIDENCY, RolesEnum.ROLE_TRAVELLER]
        },
        loadChildren: () => import('@pages/g200/g200-page.module').then((m) => m.G200PageModule)
      },
      {
        path: 'user-profile',
        canActivate: [AuthorizationGuard],
        data: {
          title: 'user',
          roles: [
            RolesEnum.ROLE_ADMIN,
            RolesEnum.ROLE_DIRECTOR,
            RolesEnum.ROLE_PRESIDENCY,
            RolesEnum.ROLE_TRAVELLER,
            RolesEnum.ROLE_ADMIN_EXT
          ]
        },
        loadChildren: () => import('@pages/user-profile/user-profile-page.module').then((m) => m.UserProfilePageModule)
      },
      {
        path: 'own-fleet',
        canActivate: [AuthorizationGuard],
        data: {
          title: 'own-fleet',
          roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_DIRECTOR, RolesEnum.ROLE_PRESIDENCY]
        },
        loadChildren: () => import('@pages/own-fleet/own-fleet-page.module').then((m) => m.OwnFleetPageModule)
      },
      {
        path: 'not-permission',
        loadChildren: () =>
          import('./pages/not-permission/not-permission-page.module').then((m) => m.NotPermissionPageModule)
      },
      {
        path: '**',
        canActivate: [AuthorizationGuard],
        data: {
          title: 'not-found',
          roles: [
            RolesEnum.ROLE_ADMIN,
            RolesEnum.ROLE_DIRECTOR,
            RolesEnum.ROLE_PRESIDENCY,
            RolesEnum.ROLE_TRAVELLER,
            RolesEnum.ROLE_ADMIN_EXT
          ]
        },
        component: MPageNotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
