import { Injectable } from '@angular/core';

import { MIconInterface } from '@mercadona/icons';
import * as svgs from '@mercadona/icons/svg';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private icons: MIconInterface[] = [
    svgs.navigationMenuRegular,
    svgs.arrowDown1Regular,
    svgs.logout1Regular,
    svgs.singleManRegular,
    svgs.binRegular,
    svgs.navigationMenuVerticalBold,
    svgs.arrowLeft1Regular,
    svgs.printTextRegular,
    svgs.pencil1Regular,
    svgs.removeBold,
    svgs.planeTrip1Regular,
    svgs.planeTakeOffRegular,
    svgs.planeLandRegular,
    svgs.calendar3Bold,
    svgs.filter1Regular,
    svgs.addBold,
    svgs.houseRegular,
    svgs.keyboardArrowRightRegular,
    svgs.view1Regular,
    svgs.informationCircleRegular,
    svgs.checkCircle1Regular,
    svgs.taskListApproveRegular,
    svgs.alertTriangleRegular,
    svgs.carRegular,
    svgs.carBold,
    svgs.dragAndDropCustom,
    svgs.dataTransferHorizontalRegular,
    svgs.viewOffRegular,
    svgs.search1Bold,
    svgs.taskListApproveRegular,
    svgs.singleNeutralBold,
    svgs.singleNeutralCircle2Bold,
    svgs.pencil1Regular,
    svgs.sendEmail2Regular
  ];

  constructor() {}

  initIcons(): void {}
}
